import React, { useState, useEffect } from 'react';
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";
import { abrirBaseDeDatosLocal, deportistaEliminadoPWS, deportistaRegistradoPWS, eliminarNadador, organizarPorTiempos, registrarClubAlTorneo, registrarDeportistaGeneral, registrarDirectivosClub, registrarNadador, sacarNadadoresDeLosClubs, traerDeportistaGeneral, traerDirectivos, traerNadadores, traerPruebasParaRegistroJPC, verificarLimpiar}  from "../controllers/configuracionFirebase";
import { useNavigate } from "react-router-dom";
import { Tooltip as Mensaje } from "react-tooltip";
import { acomodarTextoCategoria } from '../controllers/estilos';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import Swal from 'sweetalert2';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { reporteInscritos } from '../controllers/pdfs';
import { saveAs } from 'file-saver';
import { datosAdmin } from '../controllers/administrador';
import Conctatenos from './Conctatenos';

const InscripcionNadadores = ({ 
  usuario,
  idTorneoParticipando, 
  torneoEspecifico,
  setListPruebas,
  listPruebas,
  pruebasCompletas, 
  listadoCategorias}) => {

  const [ nadadores, setNadadores ] = useState([]);
  const [ globalFilter, setGlobalFilter ] = useState(null);
  const [ expandedRows, setExpandedRows ] = useState(null);
  const [ dialogInscripcion, setDialogInscripcion ] = useState(false);
  const [ dialogDirectivos, setDialogDirectivos ] = useState(false);
  const [ mostrar, setMostrar ] = useState(false);
  const [ pruebasTiempos, setPruebasTiempos ] = useState([]);
  const [ pruebasId, setPruebasId ] = useState([]);
  const [ mostrarCategorias, setMostrarCategorias ] = useState([]);
  const [ sexo, setSexo ] = useState('');
  const [ edadRegistrada, setEdadRegistrada ] = useState();
  const [ mostrarJornadas, setMostrarJornadas ] = useState([]);
  const [ deportistas, setDeportistas ] = useState([]);
  const [ deportistaGeneral, setDeportistaGeneral ] = useState();
  const [ modificar, setModificar ] = useState(false);
  const [ pdfUrl, setPdfUrl ] = useState();
  const [ directivos, setDirectivos ] = useState([{nombre: '', cel: '', rol: ''}]);

  const navigate = useNavigate();

  function descargar(){
    saveAs(pdfUrl, `Reporte Inscripción ${usuario.nombreclub}.pdf`);
  }

  function calcularEdad(fecha) {
    if(torneoEspecifico.edCumplida === 'no'){
      let hoy = new Date();
      let fechaNacimiento = fecha;
      let cumple = fechaNacimiento.split('-');
      let cumpleanosDia = parseInt(cumple[2]);
      let cumpleanosMes = parseInt(cumple[1])
      let cumpleanosAno = parseInt(cumple[0]);
      let edad = hoy.getFullYear() - cumpleanosAno
      let m = (hoy.getMonth() + 1) - cumpleanosMes;
  
      if((cumpleanosMes) <= 12){
        edad--;
      }
  
      if(cumpleanosMes > 12){
        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanosDia)) {
          edad--;
        }
      }
  
      return edad;
    }
    if(torneoEspecifico.edCumplida === 'si'){
      let hoy = new Date();
      let fechaNacimiento = fecha;
      let cumple = fechaNacimiento.split('-');
      let cumpleanosAno = parseInt(cumple[0]);
      let edad = hoy.getFullYear() - cumpleanosAno;

      return edad;
    }
  }
  
  function sacarEdadyGenero(){
    const fecha = document.getElementById('fechaNacimiento').value;
    const auxSexo = document.getElementById('genero').value;
    setSexo(auxSexo);
    if(fecha !== ""){
      if(parseInt(edadRegistrada) !== calcularEdad(fecha)){
        setMostrar(false);
      }
      setEdadRegistrada(calcularEdad(fecha));
    }
  }

  function sacarCategoriaInscita(){
    let auxCategoria = new Set();
    listPruebas.forEach((lisPrue)=>{
      const relevo = lisPrue.prueba.match(/Relevo/g);
      if(!relevo){
        lisPrue.requisitos.forEach((req)=>{
          if(req.genero === 'Mixto'){
            if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada){
              auxCategoria.add(lisPrue.categoria);
            }
          }else{
            if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada && req.genero === sexo){
              auxCategoria.add(lisPrue.categoria);
            }
          }
        })
      }
    })
    return Array.from(auxCategoria);
  }

  function elegirPruebas(){
    setMostrar(true);
    if(!edadRegistrada || sexo === ''){
      toast.error('Por favor, llena primero los campos.');
    }else{
      const mostrarJornadas = [];
      const categorias = new Set();
      torneoEspecifico.jornadas.forEach((jor)=>{
        const auxPruebas = [];
        const auxJornada = {}
        jor.categoria.forEach((cate)=>{
          const sacarCategoria = sacarCategoriaInscita();
          if(cate === sacarCategoria[0]){
            categorias.add(cate);
            jor.pruebas.forEach((prue)=>{
              const auxPrueba = listPruebas.filter((listPrue)=>{return listPrue.id === prue.idPrueba});
              if(auxPrueba.length !== 0){
                const auxP = new Set();
                const relevo = auxPrueba[0].prueba.match(/Relevo/g);
                if(!relevo){
                  auxPrueba[0].requisitos.forEach((req)=>{
                    if(req.genero === 'Mixto'){
                      if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada){
                        auxP.add(auxPrueba[0]);
                      }
                    }else{
                      if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada && req.genero === sexo){
                        auxP.add(auxPrueba[0]);
                      }
                    }
                  })
                }
                auxPruebas.push(...Array.from(auxP));
              }
            })
          }
        })
        if(auxPruebas.length !== 0){
          auxJornada.jornada = jor.jornada;
          auxJornada.pruebas = auxPruebas;
          mostrarJornadas.push(auxJornada);
        }
      })

      if(mostrarJornadas.length === 0){
        toast.error('La categoria que intenta registrar, no compite en este torneo.');
      }
      
      setMostrarJornadas(mostrarJornadas);
      setMostrarCategorias(Array.from(categorias));
    }
  }

  function registrarFirebase(id, deportistaARegistrar){
    let auxArr = [];
    const auxNadadores = [];
    nadadores.forEach((nad)=>{
      if(nad.identificacion !== deportistaARegistrar.identificacion){
        auxNadadores.push(nad);
      }
    })

    if(auxNadadores.length !== 0){
      if(auxNadadores.length < 1){
        auxArr = [deportistaARegistrar, auxNadadores];
        registrarNadador(deportistaARegistrar.nombre, id, auxArr, usuario.id);
      }else{
        auxArr = [deportistaARegistrar, ...auxNadadores];
        registrarNadador(deportistaARegistrar.nombre, id, auxArr, usuario.id);
      }
    }else{
      auxArr = [deportistaARegistrar];
      registrarNadador(deportistaARegistrar.nombre, id, auxArr, usuario.id);
    }
  }

  function nombreNadadorMayuscula(nombre){
    const nombreMinuscula = nombre.toLowerCase();
    const nombrecompleto = nombreMinuscula.split(' ');
    let nombreMayuscula = '';
    for (let i = 0; i < nombrecompleto.length; i++) {
      nombreMayuscula += nombrecompleto[i].charAt(0).toUpperCase() + nombrecompleto[i].slice(1) + " ";
    }
    return nombreMayuscula.trim();
  }

  function sacarPruebas(){
    const auxPruebas = [];
    const auxPruebasTiempos = [];
    const pruebasTimes = [];
    const pruebas = [];
    for (let i = 0; i < torneoEspecifico.jornadas.length; i++) {
      for (let j = 0; j < parseInt(torneoEspecifico.jornadas[i].cantPruebas); j++) {
        const prueba = document.getElementById(`jornada${i}prueba${j}`) !== null ? document.getElementById(`jornada${i}prueba${j}`).value : '';
        const pruebaTiempoMin = document.getElementById(`jornada${i}prueba${j}TiempoMinutos`) !== null ? document.getElementById(`jornada${i}prueba${j}TiempoMinutos`).value !== '' ? document.getElementById(`jornada${i}prueba${j}TiempoMinutos`).value : '0' : '';
        const pruebaTiempoSeg = document.getElementById(`jornada${i}prueba${j}TiempoSegundos`) !== null ? document.getElementById(`jornada${i}prueba${j}TiempoSegundos`).value !== '' ? document.getElementById(`jornada${i}prueba${j}TiempoSegundos`).value : '0' : '';
        const pruebaTiempoMili = document.getElementById(`jornada${i}prueba${j}TiempoMilisegundos`) !== null ? document.getElementById(`jornada${i}prueba${j}TiempoMilisegundos`).value !== '' ? document.getElementById(`jornada${i}prueba${j}TiempoMilisegundos`).value : '0' : '';
        
        const auxTiempos = { 
          prueba: prueba,
          record: pruebaTiempoMin + ':' + pruebaTiempoSeg + ':' + pruebaTiempoMili
        }
        
        auxPruebas.push(prueba);
        auxPruebasTiempos.push(auxTiempos);
      }
    }

    auxPruebas.forEach((auxPrue)=>{
      if(auxPrue !== ''){
        pruebas.push(auxPrue);
      }
    })
    
    auxPruebasTiempos.forEach((auxPrue)=>{
      if(auxPrue.prueba !== ''){
        pruebasTimes.push(auxPrue);
      }
    })
    
    setPruebasTiempos(pruebasTimes);
    setPruebasId(pruebas);
  }

  function validarPruebas(arr) {
    const conjunto = new Set();
    for (const elemento of arr) {
      // Verificar si el elemento no es vacío
      if (elemento !== "") {
        if (conjunto.has(elemento)) {
          // Elemento duplicado encontrado
          return true;
        }
        conjunto.add(elemento);
      }
    }
    // No se encontraron elementos duplicados
    return false;
  }

  function sacarIdentificaciones(ti){
    let identificacion = false;
    if(!modificar){
      deportistas.forEach((deport)=>{
        if(deport.identificacion === ti){
          identificacion = true;
        }
      })
    }

    return identificacion;
  }

  function sacarEdadDeLaCategoria(categoria){
    let categoriaAsignada = `${edadRegistrada}`;
    categoria.forEach((cate)=>{
      const auxCategoria = listadoCategorias.filter((listCate)=>{return listCate.valor === cate});
      const auxPrueba = listPruebas.filter((listPrue)=>{return listPrue.categoria === cate});
      if(auxPrueba.length !== 0){
        pruebasId.forEach((prueId)=>{
          const auxPruebaId = auxPrueba.filter((auxPrue)=>{return auxPrue.id === prueId});
          if(auxPruebaId.length !== 0){
            auxPruebaId[0].requisitos.forEach((req)=>{
              if(parseInt(req.edadInicio) <= edadRegistrada && parseInt(req.edadFin) >= edadRegistrada){
                if(auxCategoria[0].premiacion === 'separados'){
                  if(auxCategoria[0].grupos.length !== 0){
                    const auxGrupo = auxCategoria[0].grupos.filter((gru)=>{return parseInt(gru.edadInicio) <= edadRegistrada && parseInt(gru.edadFin) >= edadRegistrada});
                    if(auxGrupo.length !== 0){
                      categoriaAsignada = auxGrupo[0].nombre;
                    }
                  }else{
                    categoriaAsignada = `${req.edadInicio === req.edadFin ? req.edadInicio : req.edadInicio + ' - ' + req.edadFin}`;
                  }
                }else{
                  categoriaAsignada = `${req.edadInicio === req.edadFin ? req.edadInicio : req.edadInicio + ' - ' + req.edadFin}`;
                }
              }
            })
          }
        })
      }
    })
    return categoriaAsignada;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const deportistaARegistrar = {
      club: usuario.abreviatura,
      nombre: nombreNadadorMayuscula(e.target.nombre.value),
      identificacion: e.target.identificacion.value,
      fechaNacimiento: e.target.fechaNacimiento.value,
      edad: parseInt(e.target.edad.value),
      genero: e.target.genero.value,
      nombreclub: usuario.nombreclub,
      pruebas: pruebasId,
      categoria: sacarEdadDeLaCategoria(sacarCategoriaInscita())
    }
    if(validarPruebas(deportistaARegistrar.pruebas)){
      toast.error('Al parecer intentas registrar al deportista en 2 o mas pruebas iguales, eso no es posible, por favor verifica para poder continuar.');
    }else if(deportistaARegistrar.pruebas.length === 1 && deportistaARegistrar.pruebas[0] === ''){
      toast.error('No has seleccionado las pruebas todavía. En caso de que las hayas elegido y el sistema no te permita avanzar. Por favor, recarga la plataforma.');
    }else if(!validarPruebas(deportistaARegistrar.pruebas) && deportistaARegistrar.pruebas.length <= parseInt(torneoEspecifico.jornadas[0].cantPruebas)){
      if(sacarIdentificaciones(deportistaARegistrar.identificacion)){
        toast('Ya existe un/una deportista con esta identificacíon ' + deportistaARegistrar.identificacion,{
          icon: '🤨'
        })
      }else{
        if(modificar){
          if(deportistaARegistrar.pruebas.length === 0){
            toast('Debes hacer una modificación en las pruebas o darle clic en una de las pruebas.',{
              icon: '🤨'
            })
          }else{
            organizarPorTiempos(deportistaARegistrar.identificacion, pruebasTiempos, mostrarJornadas).then((org)=>{
              deportistaARegistrar.records = org;
              registrarFirebase(idTorneoParticipando, deportistaARegistrar);
            });
            if(!modificar){
              deportistaRegistradoPWS(datosAdmin.conexion, torneoEspecifico.id);
            }
            setEdadRegistrada();
            setDeportistaGeneral();
            setMostrarJornadas([]);
            setModificar(false);
            e.target.reset();
          }
        }else{
          organizarPorTiempos(deportistaARegistrar.identificacion, pruebasTiempos, mostrarJornadas).then((org)=>{
            deportistaARegistrar.records = org;
            registrarFirebase(idTorneoParticipando, deportistaARegistrar);
          });
          if(deportistaARegistrar.edad >= 10){
            registrarDeportistaGeneral(deportistaARegistrar.identificacion, deportistaARegistrar, usuario.departamento);
          }
          if(!modificar){
            deportistaRegistradoPWS(datosAdmin.conexion, torneoEspecifico.id);
          }
          setEdadRegistrada();
          setDeportistaGeneral();
          setMostrarJornadas([]);
          setModificar(false);
          e.target.reset();
        }
      }
    }else{
      toast(`Has excedido el límite máximo de pruebas permitidas, máximo (${torneoEspecifico.jornadas[0].cantPruebas}).`,{
        icon: '🤨'
      })
    }
  };
  
  function buscarDeportista(){
    traerDeportistaGeneral(document.getElementById('deportCC').value, setDeportistaGeneral, usuario.nombreclub, calcularEdad).then((dep) =>{
      setDeportistaGeneral(dep);
      setEdadRegistrada(dep.edad);
    });
  }

  function anadirCamposDirectivo(index){
    const auxDirectivos = [...directivos];
    const auxObjDirectivo = {nombre: '', cel: '', rol: ''};
    auxDirectivos.splice(index + 1, 0, auxObjDirectivo);
    setDirectivos(auxDirectivos);
  }

  function quitarCamposDirectivo(index){
    const auxDirectivos = [...directivos];
    if(auxDirectivos.length > 1){
      auxDirectivos.splice(index, 1);
      setDirectivos(auxDirectivos);
    }
  }

  function registrarDirectivos(){
    const auxDirectivos = [...directivos];
    for (let i = 0; i < directivos.length; i++) {
      const nombreDire = document.getElementById(`nombreDirectivo${i}`) !== null ? document.getElementById(`nombreDirectivo${i}`).value : '';
      const celDire = document.getElementById(`cel${i}`) !== null ? document.getElementById(`cel${i}`).value : '';
      const rolDire = document.getElementById(`rol${i}`) !== null ? document.getElementById(`rol${i}`).value : '';

      auxDirectivos[i].nombre = nombreNadadorMayuscula(nombreDire);
      auxDirectivos[i].cel = celDire;
      auxDirectivos[i].rol = rolDire;
    }

    setDirectivos(auxDirectivos);
    registrarDirectivosClub(usuario.id, auxDirectivos, torneoEspecifico.id);
  }

  function pruebasExtras(cant, prueba, indexPrueba){
    const auxCantPruebas = Array.from({ length: cant}, (_, index) => index);
     return auxCantPruebas.map((items, indexPrueExtra)=>(
      <tr key={indexPrueExtra}>
        <td>
          <div className="form-floating mb-2">
            <select style={{minWidth: '100px'}} className='form-select' id={`jornada${indexPrueba}prueba${indexPrueExtra}`} defaultValue={modificar ? deportistaGeneral['records' + indexPrueba][indexPrueExtra] ? deportistaGeneral['records' + indexPrueba][indexPrueExtra].prueba : '' : '0'} onClick={() => sacarPruebas()}>
              <option value=''>{`Ninguna`}</option>
              {
                prueba.pruebas.map((prue, indexPrue)=>{
                  return <option key={indexPrue} value={prue.id}>{prue.prueba + ' - ' + prue.categoria}</option>
                })
              }
            </select>
            <label htmlFor="genero">{prueba.pruebas.length} Pruebas Disponibles</label>
          </div>
        </td>
        <td className='d-flex justify-content-around align-items-center'>
          <div className="form-floating pb-2">
            <input style={{width: '68px'}} disabled={torneoEspecifico.regTiempo === 'no' ? true : false} type="number" id={`jornada${indexPrueba}prueba${indexPrueExtra}TiempoMinutos`} className='form-control' placeholder='0' defaultValue={modificar ? parseInt(deportistaGeneral['records' + indexPrueba][indexPrueExtra] ? deportistaGeneral['records' + indexPrueba][indexPrueExtra].record[0] : 0) : '0'} onChange={() => sacarPruebas()}/>
            <label htmlFor="deportCC">Min.</label>
          </div>
          <div className="form-floating pb-2">
            <input style={{width: '68px'}} disabled={torneoEspecifico.regTiempo === 'no' ? true : false} type="number" id={`jornada${indexPrueba}prueba${indexPrueExtra}TiempoSegundos`} className='form-control' placeholder='0' defaultValue={modificar ? parseInt(deportistaGeneral['records' + indexPrueba][indexPrueExtra] ? deportistaGeneral['records' + indexPrueba][indexPrueExtra].record[1] : 0) : '0'} onChange={() => sacarPruebas()}/>
            <label htmlFor="deportCC">Seg.</label>
          </div>
          <div className="form-floating pb-2">
            <input style={{width: '68px'}} disabled={torneoEspecifico.regTiempo === 'no' ? true : false} type="number" id={`jornada${indexPrueba}prueba${indexPrueExtra}TiempoMilisegundos`} className='form-control' placeholder='0' defaultValue={modificar ? parseInt(deportistaGeneral['records' + indexPrueba][indexPrueExtra] ? deportistaGeneral['records' + indexPrueba][indexPrueExtra].record[2] : 0) : '0'} onChange={() => sacarPruebas()}/>
            <label htmlFor="deportCC">Mili.</label>
          </div>
        </td>
      </tr>
     ))
  }

  const header = (
    <div className="d-flex justify-content-start align-items-center">
      <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
      </span>
    </div>
  );

  const agregarInscrito = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button icon="pi pi-plus" severity="success" raised tooltip='Inscribir' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogInscripcion(true)}/>
        <Button className='ms-1 me-1' icon="pi pi-file-pdf" severity="danger" raised tooltip='Reporte .pdf' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => reporteInscritos(pruebasCompletas, usuario, nadadores, setPdfUrl, torneoEspecifico)}/>
        <Button className='me-1' icon="pi pi-users" severity="primary" raised tooltip='Directivos' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogDirectivos(true)}/>
      </div>
    );
  };

  function sacarPruebasTiempos(nadador){
    const auxPrueba = [];
    nadador.records.forEach((prue)=>{
      const prueba = listPruebas.filter((listPrue)=>{return listPrue.id === prue.prueba});
      prue.nombre = prueba[0].prueba;
      auxPrueba.push(prue);
    })
    return auxPrueba;
  }

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable value={sacarPruebasTiempos(data)} emptyMessage="No hay pruebas registradas.">
        <Column header="#" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
        <Column field="nombre" header="Prueba" style={{ width: '110px'}}></Column>
        <Column field="record" header="Tiempo" style={{ width: '80px'}}></Column>
      </DataTable>
    );
  };

  const acciones = (rowIndex, nombre, insc) => {
    return (
      <div>
        <Button icon="pi pi-pencil" text severity="secondary" tooltip='Editar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => modificarInscrito(insc)}/>
        <Button className='me-1' text icon="pi pi-times" severity="danger" tooltip='Eliminar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => clickNadador(rowIndex, nombre)}/>
      </div>
    );
  };

  function clickNadador(index, nombre){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 10px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0">Esta seguro de eliminar a el/la deportista ${nombre}
      </div>`,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 4,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        const auxNadadores = [...nadadores]
        auxNadadores.splice(index, 1);
        eliminarNadador(nombre, idTorneoParticipando, usuario.id, auxNadadores);
        sacarNadadoresDeLosClubs(idTorneoParticipando, setDeportistas);
        deportistaEliminadoPWS(datosAdmin.conexion, torneoEspecifico.id);
      }
    })
  }

  function modificarInscrito(insc){
    setDialogInscripcion(true);
    torneoEspecifico.jornadas.forEach((jor, indexJor)=>{
      const auxRecords = []; 
      jor.pruebas.forEach((prueJor)=>{
        insc.records.forEach((inscPrue)=>{
          if(prueJor.idPrueba === inscPrue.prueba){
            auxRecords.push(inscPrue);
          }
        })
      })

      insc['records' + indexJor] = auxRecords;
      insc['records' + indexJor].forEach((rec)=>{
        rec.record = rec.record.length !==  3 ? rec.record.split(':') : rec.record;
      })
    })
    setEdadRegistrada(calcularEdad(insc.fechaNacimiento));
    setDeportistaGeneral(insc);
    setModificar(true);
  }

  const footerGroupDeportista = (
    <ColumnGroup>
        <Row>
            <Column footer="Total:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
            <Column footer={nadadores.length !== 0 ? ((nadadores.length * (torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorDeportista) : 0)) + (torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorClub) : 0)).toLocaleString() :(0)} />
        </Row>
    </ColumnGroup>
  );

  function sacarCantidadPruebas(jor){
    let auxPruebas = 0;
    jor.requisitos.forEach((req)=>{
      if(parseInt(req.edadStart) <= edadRegistrada && parseInt(req.edadEnd) >= edadRegistrada){
        console.log(req)
        auxPruebas = parseInt(req.cantPruebas);
      }
    })
    return auxPruebas;
  }

  useEffect(() => {
    traerNadadores(idTorneoParticipando, usuario.id, setNadadores);
  }, [setNadadores, usuario])

  useEffect(() => {
    if(!dialogInscripcion){
      setEdadRegistrada();
      setDeportistaGeneral();
      setMostrarJornadas([]);
      setModificar(false);
    }
  }, [dialogInscripcion])

  useEffect(() => {
    if(torneoEspecifico.inscripcionesCerradas){
      if(usuario.nombreclub === datosAdmin.nombre || usuario.nombreclub === 'PROWEBSPORTS'){
        abrirBaseDeDatosLocal(idTorneoParticipando);
        verificarLimpiar(idTorneoParticipando);
      }
      return navigate("/resultadostiempos");
    }
    
    if(usuario.nombreclub === datosAdmin.nombre || usuario.nombreclub === 'PROWEBSPORTS'){
      abrirBaseDeDatosLocal(idTorneoParticipando);
      verificarLimpiar(idTorneoParticipando);
    }

    function registrarClub(){
      const club = {
        idClub: usuario.id,
        abreviatura: '',
        email: usuario.email,
        nadadores: [],
        nombreclub: usuario.nombreclub,
        photo: usuario.photo
      }
      registrarClubAlTorneo(idTorneoParticipando, club , usuario.id, usuario.photo, torneoEspecifico);
    }

    registrarClub();
    traerPruebasParaRegistroJPC(setListPruebas);
    traerDirectivos(torneoEspecifico.id, usuario.id, setDirectivos);
  }, [navigate, usuario, idTorneoParticipando])

  useEffect(() => {
    sacarNadadoresDeLosClubs(idTorneoParticipando, setDeportistas);
  }, [idTorneoParticipando, setDeportistas, nadadores])

  useEffect(() =>{
    if(modificar && !dialogInscripcion || !pdfUrl){
      const auxNadadores = [...nadadores];
      const nuevoNadadores = [];
      auxNadadores.forEach((auxNad)=>{
        const objNadador = {
          club: auxNad.club,
          edad: auxNad.edad,
          fechaNacimiento: auxNad.fechaNacimiento,
          genero: auxNad.genero,
          identificacion: auxNad.identificacion,
          nombre: auxNad.nombre,
          nombreclub: auxNad.nombreclub,
          pruebas: auxNad.pruebas,
          records: [],
          categoria: auxNad.categoria
        }
        auxNad.records.forEach((rec)=>{
          const objRecord = {
            prueba: rec.prueba
          }
          if(rec.record.length === 3){
            objRecord.record = `${rec.record[0]}:${rec.record[1]}:${rec.record[2]}`
          }else{
            objRecord.record = rec.record;
          }

          objNadador.records.push(objRecord);
        })

        nuevoNadadores.push(objNadador);
      })

      setNadadores(nuevoNadadores);
    }
  },[dialogInscripcion, modificar, pdfUrl])

  return (
    <>
      <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <div className='bg-light'>
          <div className="alert alert-success alert-dismissible fade show" role="alert">
            <h1>{torneoEspecifico.titulo ? torneoEspecifico.titulo.toUpperCase() : ''}</h1>
            <p style={{textAlign: 'justify'}}>
              Descarga la programación resaltada con tus deportistas de manera rápida y sencilla. 
              Solo ingresa al menú, selecciona "Programación" y resalta con uno de los 4 colores disponibles, 
              según tu preferencia. Olvídate de hacer anotaciones manuales durante el evento; 
              ahora puedes tenerlo todo listo con un solo clic.
            </p>   
          </div>
          <Toolbar left={agregarInscrito}></Toolbar>
          <DataTable value={nadadores} selectionMode="single" dragSelection expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} header={header} globalFilter={globalFilter} size='small' stripedRows paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} emptyMessage="No hay inscritos aún." 
          footerColumnGroup={footerGroupDeportista} tableStyle={{ minWidth: '60rem', margin: 'auto'}}>
            <Column expander style={{ width: '30px' }} />
            <Column header="#" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
            <Column field="nombre" header="Nombre" style={{ width: '180px', fontWeight: 'bold' }}></Column>
            <Column field="identificacion" header="Identificación" style={{ width: '70px'}}></Column>
            <Column field="fechaNacimiento" header="Fecha Nto" style={{ width: '110px'}}></Column>
            <Column field="edad" header="Edad" style={{ width: '50px'}}></Column>
            <Column field="genero" header="Sexo" style={{ width: '50px'}}></Column>
            <Column header="Aporte" body={() => {return `$${torneoEspecifico.length !== 0 ? parseInt(torneoEspecifico.valorDeportista).toLocaleString() : 0}`}} style={{ width: '80px'}}></Column>
            <Column body={(insc, indexInsc)=> !globalFilter ? acciones(indexInsc.rowIndex, insc.nombre, insc) : ''} style={{ width: '106px'}}></Column>
          </DataTable>
        </div>
        <Dialog visible={dialogInscripcion} style={{ width: '46rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header='INSCRIPCIÓN' modal className="p-fluid" onHide={() => setDialogInscripcion(false)}>
          <div className="field">
          <div className="alert alert-info alert-dismissible fade show mb-0" role="alert">
            <b>INFORMACIÓN:</b> Nuestras plataformas solo guardan información de deportistas que hayas inscrito, con 10 años o más, en cualquiera de nuestras plataformas. Estamos trabajando para ampliar esta cobertura. Si tienes deportistas de 10 años o más, puedes agilizar el proceso de inscripción usando la opción "Buscar deportista con identificación", lo que te permitirá hacer inscripciones de manera más rápida y sencilla.
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          <form onSubmit={onSubmit} className='text-center'>
            <div className="form-floating">
              <input type="number" className='form-control mb-1' id='deportCC'/>
              <label htmlFor="deportCC">Buscar deportista con identificación</label>
            </div>
            <Button type='button' icon='pi pi-search' label='Buscar' className='btn btn-secondary' style={{borderRadius: '10px'}} onClick={() => buscarDeportista()}></Button>
            <hr />
            <input className='form-control' type="hidden" name="edad" id='edad' value={edadRegistrada || ''}/>
            <label htmlFor="edad" className='w-100 mb-2'>Edad Inscrito: {edadRegistrada || '0'} Años</label>
            <div className='d-flex flex-wrap justify-content-between'>
              <div className="form-floating">
                <input className='form-control mb-2' type="text" maxLength={29} name="nombre" id='nombre' required defaultValue={deportistaGeneral ? deportistaGeneral.nombre : ''}  style={{width: '270px'}}/>
                <label htmlFor="nombre">Nombre Completo</label>
              </div>
              <div className="form-floating">
                <input className='form-control mb-2' type="number" name="identificacion" id='identificacion' required disabled={modificar} defaultValue={deportistaGeneral ? deportistaGeneral.identificacion : ''} style={{width: '150px'}}/>
                <label htmlFor="identificacion">Identificación</label>
              </div>
              <div className="form-floating">
                <input className='form-control mb-2' type="date" name="fechaNacimiento" id='fechaNacimiento' required defaultValue={deportistaGeneral ? deportistaGeneral.fechaNacimiento : ''} onChange={sacarEdadyGenero} style={{width: '190px'}}/>
                <label htmlFor="fechaNacimiento">Fecha De Nacimiento</label>
              </div>
              <div className="form-floating mb-2">
                <select name="genero" id="genero" className='form-select' aria-label="Default select example" required onChange={sacarEdadyGenero} style={{width: '190px'}}>
                  <option value="">Elige</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Masculino">Masculino</option>
                </select>
                <label htmlFor="genero">Género</label>
              </div>
            </div>
            <Button type='button' icon='pi pi-list' label='Elegir Pruebas' className='btn btn-primary' style={{borderRadius: '10px'}} onClick={() => elegirPruebas()}></Button>
            <Mensaje
              anchorId={`nombre`}
              place="bottom"
              content={'Solo se admiten 29 caracteres incluyendo el espacio.'}
              className="bg-primary shadow"
              style={{ zIndex: 1}}
            />
            {mostrar ?(
              mostrarJornadas.length !== 0 ? (
                mostrarJornadas.map((prueba, indexPrueba)=>{
                    return <table key={indexPrueba} className="table table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th colSpan={4}>{`Compite en la Jornada ${prueba.jornada + 1}`}</th>
                      </tr>
                      <tr>
                        <th colSpan={4}>{acomodarTextoCategoria(mostrarCategorias, listadoCategorias)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pruebasExtras(sacarCantidadPruebas(torneoEspecifico.jornadas[prueba.jornada]), prueba, indexPrueba)}
                    </tbody>
                  </table>
                })
              ):(
                <></>
              )
            ):(
              <></>
            )}
            
          <Button type='submit' icon='pi pi-user-plus' label={`${deportistaGeneral ? deportistaGeneral.records ? 'Modificar' : 'Inscribir Al Evento' : 'Inscribir Al Evento'}`} className='btn btn-success mt-2' style={{borderRadius: '10px'}} onClick={() => sacarPruebas()}></Button>
          </form>
            <hr />
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
              <b>IMPORTANTE:</b> Para asegurar el mejor funcionamiento al calcular la edad, evita hacer copiar y pegar o escribir directamente en el campo de la fecha de nacimiento. En su lugar, te recomendamos buscar directamente la fecha de nacimiento del deportista en el calendario para asegurarte de que la información sea precisa y se ingrese correctamente.
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          </div>
        </Dialog>
        <Dialog visible={dialogDirectivos} style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Registro Directivos`} modal className="p-fluid" onHide={() => setDialogDirectivos(false)}>
          <div className="field">
            <div>
              <div className="form-floating">
                <input type="text" disabled id='abrev' className='form-control mb-2' defaultValue={usuario ? usuario.abreviatura : ''}/>
                <label htmlFor="abrev">Abreviatura</label>
              </div>
            </div>
            {directivos.map((dire, indexDire) =>{
              return <div key={indexDire} className='d-flex flex-wrap justify-content-between'>
              <div className="form-floating">
                <input className='form-control mb-2' type="text" maxLength={29} id={`nombreDirectivo${indexDire}`} defaultValue={dire.nombre} />
                <label htmlFor={`nombreDirectivo${indexDire}`}>Nombre Completo</label>
              </div>
              <div className="form-floating">
                <input className='form-control mb-2' type="number" id={`cel${indexDire}`} style={{width: '140px'}} defaultValue={dire.cel}/>
                <label htmlFor={`cel${indexDire}`}>No. Celular</label>
              </div>
              <div className="form-floating mb-2">
                <select id={`rol${indexDire}`} className='form-select' defaultValue={dire.rol}>
                  <option value="">Elige</option>
                  <option value="Entrenador/a Principal">Entrenador/a Principal</option>
                  <option value="Entrenador/a">Entrenador/a</option>
                  <option value="Delegado/a">Delegado/a</option>
                </select>
                <label htmlFor={`rol${indexDire}`}>Rol</label>
              </div>
              <div className='d-flex align-items-center'>
                {indexDire === directivos.length - 1 ? (
                  <Button icon="pi pi-plus" className='me-1' severity="success" raised style={{borderRadius: '50%'}} onClick={() => anadirCamposDirectivo(indexDire)}/>
                ):(
                  <></>
                )}
                <Button icon="pi pi-minus" severity="danger" raised style={{borderRadius: '50%'}} onClick={() => quitarCamposDirectivo(indexDire)}/>
              </div>
            </div>
            })}
            <Button icon="pi pi-check-circle" label='Registrar Directivos' className='btn btn-success' onClick={() => registrarDirectivos()}/>
          </div>
        </Dialog>
        <Dialog visible={pdfUrl ? true : false} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Reporte ${usuario.nombreclub}`} modal className="p-fluid" onHide={() => setPdfUrl()}>
          <div className="field">
            <div className='mb-2 pdf-min pdf-max' style={{width: '100%'}}>
              <embed src={pdfUrl} width='100%' height='100%' type='application/pdf'/>
            </div>
            <div className='d-flex justify-content-between mb-2'>
              <Button icon="pi pi-download" className='p-2' text tooltip='Descargar Reporte' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '10px'}} onClick={() => descargar()}></Button>
            </div>
          </div>
        </Dialog>
      </motion.div>
      <div className='contactenos' style={{height: '354px'}}>
        <Conctatenos/>
      </div>
    </>
  );
}

export default InscripcionNadadores;