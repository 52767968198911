import React, { useEffect, useState } from "react";
import "../styles/Sesion.css";
import Login from "./Login";
import { FcApproval, FcHighPriority } from "react-icons/fc";
import { arreglarTiempoEstandar } from '../controllers/pdfs';
import Registrar from "./Registrar";
import { motion } from "framer-motion";
import Panel from "./Panel";
import { Dialog } from 'primereact/dialog';
import { aplicarVerificacionCorreo, cambiarPassword, cancelarTiemposResultadosFamilia, ingresarViaQrCode, registrarReaccion, traerReacciones, traerResultadosFamilia, traerTitulosTorneosFamilia, verificarCode } from "../controllers/configuracionFirebase";
import { InputText } from "primereact/inputtext";
import { getCookieReaccion, setReaccionCookie } from "../controllers/cookies";
import { anioAutor } from "../controllers/tiempoLimiteInscripcion";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { Password } from "primereact/password";
import toast from "react-hot-toast";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Sesion = ({ usuario, setUsuario }) => {
  /* este estado nos ayudara a saber si se va registrar o iniciar sesion. */
  const [ login, setLogin ] = useState(true);
  const [ nuevaContrasena, setNuevaContrasena ] = useState('');
  const [ dialogNuevaContrasena, setDialogNuevaContrasena ] = useState(false);
  const [ dialogResultados, setDialogResultados ] = useState(false);
  const [ dialogHistorial, setDialogHistorial ] = useState(false);
  const [ dialogOpiniones, setDialogOpiniones ] = useState(false);
  const [ dialogChampions, setDialogChampions ] = useState(false);
  const [ historialDeportista, setHistorialDeportista ] = useState();
  const [ resultados, setResultados ] = useState([]);
  const [ titulosTorneos, setTitulosTorneos ] = useState([]);
  const [ globalFilter, setGlobalFilter ] = useState('');
  const [ cantPuestos, setCantPuestos ] = useState('');
  const [ identidad, setIdentidad ] = useState('');
  const [ nombreClub, setNombreClub ] = useState('');
  const [ nombresClubs, setNombresClubs ] = useState('');
  const [ reacciones, setReacciones ] = useState();
  const [ contadorQueryQr, setContadorQueryQr ] = useState(0);
  const [ contadorQueryNewPassword, setContadorNewPassword ] = useState(0);
  const [ animateList, setAnimateList ] = useState(false);
  const resultadosRef = React.useRef(resultados);
  const query = useQuery();
  const navigate = useNavigate();

  function manejoRendimiento(record, tiempo){
    if(tiempo && record && record !== '0:0:0' && tiempo !== '0:0:0'){
      const dividirTiempoA = record.split(':');
      const dividirTiempoB = tiempo.split(':');
      const tiempoMinA = dividirTiempoA[0];
      const tiempoSegA = dividirTiempoA[1];
      const tiempoMiliA = dividirTiempoA[2];
      const tiempoMinB = dividirTiempoB[0];
      const tiempoSegB = dividirTiempoB[1];
      const tiempoMiliB = dividirTiempoB[2];
      const tiempoA = parseInt(tiempoMinA) * 60 * 1000 + parseInt(tiempoSegA) * 1000 + parseInt(tiempoMiliA);
      const tiempoB = parseInt(tiempoMinB) * 60 * 1000 + parseInt(tiempoSegB) * 1000 + parseInt(tiempoMiliB);

      const resultadoRecords = parseFloat((tiempoA - tiempoB));
      
      return resultadoRecords;
    }else{
      return 0;
    }
  }

  function arreglarFormatoTiempo(resultadoRecords){
    const auxResultadoRecords = Math.abs(resultadoRecords);
    const minutos = Math.floor(auxResultadoRecords / (60 * 1000));
    const segundos = Math.floor((auxResultadoRecords % (60 * 1000)) / 1000);
    const milisegundos = auxResultadoRecords % 1000;
    if(minutos !== 0){
      return minutos + ':' + segundos + '.' + milisegundos;
    }else{
      return segundos + '.' + milisegundos;
    }
  }

  function sacarHistorialDeportista(nad){
    setDialogHistorial(true);
    const auxHistorial = [];
    resultados.forEach((result)=>{
      const objHistorial = {};
      result.nadadores.forEach((nadad, indexNadad)=>{
        if(nadad.identificacion === nad.identificacion && !nadad.equipo){
          objHistorial.puesto = indexNadad + 1;
          objHistorial.nPrueba = result.id + 1;
          objHistorial.prueba = result.prueba.prueba;
          objHistorial.record = nadad.equipo === null ? nadad.record : '0:0:0';
          objHistorial.tiempo = nadad.tiempo;
          auxHistorial.push(objHistorial);
        }

        if(nadad.equipo){
          const auxNombre = nadad.equipo.filter((eq) => {return eq === nad.nombre});
          if(auxNombre.length !== 0){
            objHistorial.puesto = indexNadad + 1;
            objHistorial.nPrueba = result.id + 1;
            objHistorial.prueba = result.prueba.prueba;
            objHistorial.record = nadad.equipo === null ? nadad.record : '0:0:0';
            objHistorial.tiempo = nadad.tiempo;
            auxHistorial.push(objHistorial);
          }
        }
      })
    })
    nad.pruebas = [...auxHistorial];
    setHistorialDeportista(nad);
  }

  function onClicReaccion(reaccion){
    if(reacciones){
      if(getCookieReaccion('reaxx') !== reaccion){
        if(getCookieReaccion('reaxx') !== ''){
          reacciones[getCookieReaccion('reaxx')] = `${parseInt(reacciones[getCookieReaccion('reaxx')]) - 1}`; 
          reacciones[reaccion] = `${parseInt(reacciones[reaccion]) + 1}`;
        }else{
          reacciones[reaccion] = `${parseInt(reacciones[reaccion]) + 1}`; 
        }
        registrarReaccion(reacciones);
      }
      setReaccionCookie('reaxx', reaccion);
    }
  }

  function onClicTituloTorneo(){
    const idTorneo = document.getElementById('idTorneo').value;
    if(idTorneo !== ''){
      traerResultadosFamilia(idTorneo, setResultados);
    }
  }

  function formatNumeroReacciones(number) {
    if (number >= 1e6) {
      // Si es un millón o más, formatea en millones (M)
      return (number / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (number >= 1e3) {
      // Si es mil o más, formatea en miles (k)
      return (number / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
    } else {
      // Si es menos de mil, devuelve el número original
      return number.toString();
    }
  }

  function onSubmitRestablecerContrasena(e){
    e.preventDefault();
    if(query.get('oobCode')){
      verificarCode(query.get('oobCode')).then(()=>{
        toast.success('Codigo de verificación correcto.');
        const auxNuevaContrasena = nuevaContrasena;
        cambiarPassword(query.get('oobCode'), auxNuevaContrasena);
        setDialogNuevaContrasena(false);
        navigate('/');
      }).catch((error)=>{
        switch (error.code) {
          case 'auth/expired-action-code':
            toast.error("El enlace de restablecimiento de contraseña ha expirado. Por favor, solicita un nuevo enlace.");
            break;
          case 'auth/invalid-action-code':
            toast.error("El enlace de restablecimiento de contraseña no es válido o ya ha sido utilizado.");
            break;
          case 'auth/user-disabled':
            toast.error("Esta cuenta ha sido deshabilitada. Contacta al soporte para obtener ayuda.");
            break;
          case 'auth/user-not-found':
            toast.error("No se pudo encontrar una cuenta asociada a este enlace. Verifica tu correo electrónico o intenta nuevamente.");
            break;
          case 'auth/weak-password':
            toast.error("La nueva contraseña es demasiado débil. Asegúrate de que tenga al menos 6 caracteres.");
            break;
          default:
            toast.error("Ocurrió un error inesperado. Por favor, intenta nuevamente.");
        }
      })
    }else{
      toast.error('¡UPS! Ocurrio un error. Intentalo de nuevo.');
    }
    e.target.reset();
  }

  useEffect(() => {
    // Comparamos los arreglos anterior y actual para detectar cambios de posición
    if (JSON.stringify(resultadosRef.current) !== JSON.stringify(resultados)) {
      // Si hay una diferencia en los arreglos, activamos la animación
      setAnimateList(true);
    }

    // Actualizamos la referencia con el nuevo estado del arreglo
    resultadosRef.current = resultados;

    const animationDuration = 1000; // Duración de la animación en milisegundos (aquí 0.5 segundos)
    const timer = setTimeout(() => {
      setAnimateList(false);
    }, animationDuration);
 
    return () => clearTimeout(timer);
  }, [setAnimateList, resultadosRef, resultados])

  useEffect(() =>{
    if(!dialogResultados){
      cancelarTiemposResultadosFamilia();
    }else{
      traerTitulosTorneosFamilia(setTitulosTorneos);
    }
  },[dialogResultados])

  useEffect(() =>{
    traerReacciones(setReacciones);
  },[])

  useEffect(() =>{
    if(query.get('source') === 'qr' && contadorQueryQr === 0){
      ingresarViaQrCode(usuario, setDialogResultados);
      setContadorQueryQr(1);
    }

    if(query.get('oobCode') && query.get('mode') === 'resetPassword'){
      setDialogNuevaContrasena(true);
      setContadorNewPassword(1);
    }

    if(query.get('oobCode') && query.get('mode') === 'verifyEmail' && contadorQueryQr === 0){
      aplicarVerificacionCorreo(query.get('oobCode'));
      setContadorQueryQr(1);
      navigate('/');
    }
  },[usuario, query])

  useEffect(() =>{
    if(!dialogNuevaContrasena && contadorQueryNewPassword === 1){
      navigate('/');
      setDialogNuevaContrasena(false); 
    }
  },[dialogNuevaContrasena])

  useEffect(() =>{
    function sacarNombresClubes(){
      const auxNombres = new Map();
      resultados.forEach((res)=>{
        res.nadadores.forEach((nad)=>{
          const objNombres = {
            name: nad.nombreclub,
            code: nad.club
          }
          auxNombres.set(nad.nombreclub, objNombres);
        })
      })

      setNombresClubs(Array.from(auxNombres.values()));
    }

    sacarNombresClubes();
  },[resultados])

  return usuario ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="bgColombia"></div>
      <Panel usuario={usuario} setUsuario={setUsuario}/>
    </motion.div>
  ) : (
    <>
      <div className="w-100 d-flex justify-content-center align-items-center posicion-sesion" style={{height: '100%'}}>
        <div className="w-100">
          <ul className="circles" style={{zIndex: -10}}>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <motion.div
            className="m-auto sesion"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Registrar setLogin={setLogin} loginv={login} />
            <Login setLogin={setLogin} loginv={login} setDialogResultados={setDialogResultados} />
            <div className={login ? "izquierda sesion-i" : "derecha sesion-i"}>
              <img
                className={login ? "img1 img" : "img2 img"}
                src={
                  login
                    ? require("../img/img-logo-1.webp")
                    : require("../img/img-logo-2.webp")
                }
                alt="logos"
              />
            </div>
          </motion.div>
          <motion.div className="prowebsports"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          >
            <Link to='https://www.prowebsports.com.co/inicio' target="_blank" className="mt-3" style={{fontSize: '12px', textDecoration: 'none'}}>© 2023 - {anioAutor()} prowebsports</Link>
          </motion.div>
        </div>
      </div>
      <Dialog visible={dialogNuevaContrasena} style={{ width: '30em' }} breakpoints={{ '700px': '100%' }} header="Restablecer Contraseña" modal className="p-fluid" onHide={() => setDialogNuevaContrasena(false)}>
        <div className="field">
          <form onSubmit={onSubmitRestablecerContrasena}>
            <div className="d-flex justify-content-center">
              <img
                className="w-50 m-auto"
                src={require("../img/img-logo-swimmingt.webp")} 
                alt="logo de prowebsports"
              />
            </div>
            <div className="p-inputgroup flex-1 mb-1">
              <span className="p-inputgroup-addon">
                  <i className={nuevaContrasena.length >= 6 ? 'pi pi-unlock' : 'pi pi-lock'}></i>
              </span>
              <Password value={nuevaContrasena} onChange={(e) => setNuevaContrasena(e.target.value)} placeholder="Contraseña" toggleMask minLength={6} required
              className={`${nuevaContrasena !== '' && nuevaContrasena.length >= 6 ? '' : 'p-invalid'}`}
              feedback={false}/>
            </div>
            <input type="submit" className="btn btn-success w-100" value='Restablecer' />
          </form>
        </div>
      </Dialog>
      <Dialog visible={dialogResultados} style={{ width: '100%' }} header="Resultados en tiempo real" modal className="p-fluid" onHide={() => setDialogResultados(false)}>
      <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <h4>¡IMPORTANTE!</h4>
          <p className="mb-0">¡Hola a todos! Les recordamos que no es necesario recargar la plataforma, ya que los resultados se actualizan en tiempo real. Este espacio es para los familiares sin acceso directo a la plataforma. Entrenadores, recuerden que pueden acceder directamente a la plataforma para obtener información detallada. ¡Gracias por su colaboración y paciencia!</p>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        {/*
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          <h4>¡INICIATIVA PROWEBSPORTS CHAMPIONS!</h4>
          <div className="d-flex flex-wrap justify-content-center aling-items-center">
            <img
              src={require("../img/mascotapws.webp")}
              className="card-img-top m-auto position-relative"
              alt="imagen del torneo"
              style={{ width: "50%", maxWidth: '250px', filter: "drop-shadow(-1px 1px 2px black)"}}
            />
            <p className="mb-0" 
              style={{
                fontSize: "14px",
                fontFamily: "Poppins",
                width: '50%'
              }}>
              ¡Anima a tu hijo a unirse a PROWEBSPORTS CHAMPIONS! <br /> Este emocionante proyecto premia el talento y el esfuerzo en la natación. Con una afiliación anual de COP 50,000, los jóvenes atletas tendrán la oportunidad de competir y ganar atractivos premios en efectivo. Completa el formulario para conocer todos los detalles y cómo tu hijo puede beneficiarse. ¡No dejes pasar esta oportunidad! 
              <Button icon='pi pi-comments' type="button" label='Llenar Formulario' className="btn btn-primary mb-2" onClick={() => setDialogChampions(true)}></Button>
            </p>
          </div>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        */}
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          <h4>¡INFORMACIÓN!</h4>
          <p className="mb-0"
          style={{
            fontSize: "14px",
            fontFamily: "Poppins",
          }}>¡Hola a todos! Nos encantaría conocer sus comentarios y opiniones sobre nuestra plataforma. Sus sugerencias y experiencias son muy importantes para nosotros y nos ayudan a mejorar continuamente. Por favor, tómense un momento para compartir cualquier idea o problema que hayan encontrado. 
            ¡Gracias por su colaboración y apoyo! 
            <Button icon='pi pi-comments' type="button" label="¡Quiero comentar!" className="btn btn-primary" onClick={() => setDialogOpiniones(true)}></Button>
          </p>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div className="form-floating mb-2">
          <select name="idTorneo" id="idTorneo" className='form-select' onChange={() => onClicTituloTorneo()}>
            <option value="">Elige el evento</option>
            {titulosTorneos.length !== 0 ? (
              titulosTorneos.map((torn,indexTorn)=>{
                return <option key={indexTorn} value={torn.idTorneo}>{torn.titulo}</option>
              }))
              :(
              <option value="">Sin eventos aún</option>
              )
            }
          </select>
          <label htmlFor="idTorneo">Eventos</label>
        </div>
        {resultados.length !== 0 ?(
          <div className="d-flex flex-wrap justify-content-center align-items-center mb-1">
            <span className="p-input-icon-left" style={{width: '220px'}}>
              <i className="pi pi-search" />
              <InputText
                type="number"
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Numero de prueba"
              />
            </span>
            <span className="p-input-icon-left" style={{width: '220px'}}>
              <i className="pi pi-list" />
              <InputText
                type="number"
                onChange={(e) => setCantPuestos(e.target.value)}
                placeholder="Cantidad Puestos"
              />
            </span>
            <span className="p-input-icon-left" style={{width: '220px'}}>
              <i className="pi pi-user" />
              <InputText
                type="number"
                onChange={(e) => setIdentidad(e.target.value)}
                placeholder="Ti/Cc Deportista"
              />
            </span>
            <span className="p-input-icon-left" style={{width: '220px'}}>
              <i className="pi pi-users" />
              <Dropdown value={nombreClub} onChange={(e) => setNombreClub(e.value)} options={nombresClubs} optionLabel="name" 
                placeholder="Elige el club" />
            </span>
          </div>
        ):(
          <></>
        )}
        <div className="d-flex flex-wrap justify-content-center text-center">
          {resultados.length !== 0 ?(
            resultados.filter((resu)=>((resu.id + 1) + '').includes(globalFilter)).map((result, indexResult)=>{
              return <form key={indexResult} className='formulario-tablas table-responsive-sm'>
                <motion.table style={{margin: '4px', width: '530px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration: 1}}
                className='shadow tabla-tiempos'
                >
                  <thead className='text-light'>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th className='id fs-4'>{result.id + 1}</th>
                      <th style={{fontSize: '18px', borderLeft: '1px solid gray'}} colSpan={6}>{result.categoria}</th>
                    </tr>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th style={{fontSize: '18px'}} colSpan={7}>{result.prueba.prueba}</th>
                    </tr>
                    <tr>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Pto</th>
                      <th style={{fontSize: '15px'}}></th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Deportista</th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>T. Insc.</th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Tiempo</th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Rendim.</th>
                      <th style={{fontSize: '15px'}}>Equipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result.nadadores.slice(0, cantPuestos === '' ? result.nadadores.length : cantPuestos).map((nadador, indexNadador)=>{
                      return <motion.tr key={indexNadador}
                      initial={{ x: -1000, opacity: 0}}
                      animate={{ x: animateList ? -1000 : 0.1, opacity: animateList ? 0 : 1}}
                      transition={{ duration: 0.5, delay: `0.${indexNadador}`, type: 'spring'}}
                      style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexNadador}`}}
                      >
                        {indexNadador === 0 ? <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-1.png")} alt="medalla primer lugar" /></td> : indexNadador === 1 ? <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-2.png")} alt="medalla segundo lugar" /></td> :  indexNadador === 2 ? <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-3.png")} alt="medalla tercer lugar" /></td> : <td className={`pt-2 pb-2 ${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}>{indexNadador + 1}</td>}
                        {nadador.equipo === null ?(
                          <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>
                            <Button type='button' severity='success' icon='pi pi-user-plus' className='btn' style={{borderRadius: '50%', width: '2rem', height: '2rem', padding: 0}} onClick={() => sacarHistorialDeportista(nadador)}/>
                          </td>
                        ):(
                          <td style={{background: 'white'}}></td>
                        )}
                        {nadador.equipo !== null ?(
                          <td className={`dropdown ${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>
                            <span className='btn btn-secondary btn-sm dropdown-toggle' id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                              {nadador.nombre}</span>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                              {nadador.equipo.map((eq, indexEq)=>{
                                return <li key={indexEq} className="dropdown-item">{eq}</li>
                              })}
                            </ul>
                          </td>
                          ):(
                            <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{nadador.nombre}</td>
                        )}
                        <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{nadador.record && nadador.record !== '0:0:0' ? arreglarTiempoEstandar(nadador.record) : nadador.tiempo === '0:0:0' ? 'NSP' : nadador.tiempo === '61:0:0' ? 'DSC' : `${arreglarTiempoEstandar(nadador.tiempo)}`}</td>
                        <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{nadador.tiempo === '0:0:0' ? 'NSP' : nadador.tiempo === '61:0:0' ? 'DSC' : `${arreglarTiempoEstandar(nadador.tiempo)}`}</td>
                        <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{manejoRendimiento(nadador.record, nadador.tiempo) >= 0 && nadador.tiempo !== '61:0:0' && nadador.tiempo !== '0:0:0'? 
                          <label className='d-flex align-items-center justify-content-center'><FcApproval />{arreglarFormatoTiempo(manejoRendimiento(nadador.record, nadador.tiempo))}</label>
                          : 
                          <label className='d-flex align-items-center justify-content-center'><FcHighPriority />{arreglarFormatoTiempo(manejoRendimiento(nadador.record, nadador.tiempo === '61:0:0' ? '0:0:0' : nadador.tiempo))}</label>
                        }</td>
                        <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '10px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{nadador.nombreclub}</td>
                      </motion.tr>
                    })}
                  </tbody>
                </motion.table>
              </form> 
            })
          ):(
            <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{duration: 1}}
            >
              <div className='p-5 text-center'>
                <img className='m-auto' style={{width: '230px'}} src={require('../img/img-logo-swimmingt.webp')} alt="logo prowebsports" />
                <h1>¡Bienvenido!</h1>
                <p>No tenemos registros de tiempos aún.</p>
              </div>
            </motion.div>
          )}
        </div>
        <motion.div className="text-center"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{duration: 1}}
        >
          <h5>¿Qué opinas de nuestra plataforma?</h5>
          <div className="d-flex justify-content-around mt-2 m-auto" style={{width: '200px'}}>
            <div className="position-relative">
              <img className='img-reaccion' src={require('../img/gif-meencanta.gif')} alt="emoji" onClick={() => onClicReaccion('meencanta')} style={{cursor:'pointer', width: '50px'}}/>
              <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'meencanta' ? 'bg-danger' : 'bg-success'}`} style={{top: '6px'}}>
                {reacciones ? formatNumeroReacciones(parseInt(reacciones.meencanta)) : '0'}
              </span>
            </div>
            <div className="position-relative">
              <img className='img-reaccion' src={require('../img/gif-like.gif')} alt="emoji" onClick={() => onClicReaccion('like')} style={{cursor:'pointer', width: '50px'}}/>
              <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'like' ? 'bg-danger' : 'bg-success'}`} style={{top: '6px'}}>
                {reacciones ? formatNumeroReacciones( parseInt(reacciones.like)) : '0'}
              </span>
            </div>
            <div className="position-relative" style={{top: '12px'}}>
              <img className='img-reaccion' src={require('../img/gif-dislike.gif')} alt="emoji" onClick={() => onClicReaccion('dislike')} style={{cursor:'pointer', width: '50px'}}/>
              <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'dislike' ? 'bg-danger' : 'bg-success'}`} style={{top: '-4px'}}>
                {reacciones ? formatNumeroReacciones(parseInt(reacciones.dislike)) : '0'}
              </span>
            </div>
          </div>
        </motion.div>
      </Dialog>
      <Dialog visible={dialogHistorial} style={{ width: '50em' }} breakpoints={{ '700px': '100%' }} header="Historial Deportivo" modal className="p-fluid" onHide={() => setDialogHistorial(false)}>
        <div className="field">
          {historialDeportista ?(
            <div>
              <h1 className='text-center mb-0' style={{fontFamily: 'Poppins', background: 'rgb(212,212,212)'}}>
                {historialDeportista?.equipo === null ? (
                  historialDeportista?.nombre
                ):(
                  historialDeportista?.equipo.map((eq, indexEq)=>{
                    return (historialDeportista?.equipo.length - 1) !== indexEq ? eq + ' - ' : eq;
                  })
                )}
              </h1>
              <h5 className='text-center bg-light' style={{fontFamily: 'Poppins'}}>
                {historialDeportista?.nombreclub}
              </h5>
              <div className='mt-4 mb-4 p-3 d-flex flex-wrap justify-content-around align-items-center' style={{fontFamily: 'Poppins', background: 'rgb(212,212,212)'}}>
                <p className='m-0'><b>Edad:</b> {historialDeportista?.edad}</p>
              </div>
              {historialDeportista?.pruebas.map((prue, indexPrue)=>{
                return <table key={indexPrue} className='w-100 table table-bordered table-striped text-center'>
                <thead>
                  <tr style={{fontFamily: 'Poppins', background: 'rgb(212,212,212)'}}>
                    <th className='fs-6'>{prue.nPrueba}</th>
                    <th className='fs-6' colSpan={4}>{prue.prueba}</th>
                  </tr>
                  <tr>
                    <th>Posición</th>
                    <th>T. Insc.</th>
                    <th>Tiempo</th>
                    <th>Rendim.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  {prue.puesto === 1 ? <td style={{fontFamily: 'Poppins'}}><img style={{width: '25px'}} src={require("../img/icon-medalla-1.png")} alt="medalla primer lugar" /></td> : prue.puesto === 2 ? <td style={{fontFamily: 'Poppins'}}><img style={{width: '25px'}} src={require("../img/icon-medalla-2.png")} alt="medalla segundo lugar" /></td> :  prue.puesto === 3 ? <td style={{fontFamily: 'Poppins'}}><img style={{width: '25px'}} src={require("../img/icon-medalla-3.png")} alt="medalla tercer lugar" /></td> : <td className={`pt-2 pb-2`} style={{fontFamily: 'Poppins'}}>{prue.puesto}</td>}
                    <td>{arreglarTiempoEstandar(prue.record)}</td>
                    <td>{prue.tiempo !== '61:0:0' ? prue.tiempo !== '0:0:0' ? arreglarTiempoEstandar(prue.tiempo) : 'NSP' : 'DSC' }</td>
                    <td>{manejoRendimiento(prue.record, prue.tiempo) >= 0 && prue.tiempo !== '61:0:0' && prue.tiempo !== '0:0:0'? 
                      <label className='d-flex align-items-center justify-content-center'><FcApproval />{arreglarFormatoTiempo(manejoRendimiento(prue.record, prue.tiempo))}</label>
                      : 
                      <label className='d-flex align-items-center justify-content-center'><FcHighPriority />{arreglarFormatoTiempo(manejoRendimiento(prue.record, prue.tiempo === '61:0:0' ? '0:0:0' : prue.tiempo))}</label>
                    }</td>
                  </tr>
                </tbody>
              </table>
              })}
            </div>
          ):(
            <></>
          )}
        </div>
      </Dialog>
      <Dialog visible={dialogChampions} style={{ width: '50em', height: '2397px' }} breakpoints={{ '700px': '100%' }} header="Encuesta PROWEBSPORTS CHAMPIONS" modal className="p-fluid" onHide={() => setDialogChampions(false)}>
        <div className="field">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfsnrFovw7rE2W2ROvGPF7fgB3-jp_QrSC9lxhZwMiC1BRiZg/viewform?embedded=true" width="100%" height="2400" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
        </div>
      </Dialog>
      <Dialog visible={dialogOpiniones} style={{ width: '50em', height: '1193px' }} breakpoints={{ '700px': '100%' }} header="Encuesta Opiniones e Ideas" modal className="p-fluid" onHide={() => setDialogOpiniones(false)}>
        <div className="field">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfZPTAeabxKHYUQJNYs4kXfg-lcH7dUtaBhtz9erx1pI5dlCw/viewform?embedded=true" width="100%" height="1280" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
        </div>
      </Dialog>
    </>
  );
};

export default Sesion;
